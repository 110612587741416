import { useEffect, useRef, useState } from "react";
import { motion, useMotionValue, useSpring } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper/modules';

function OurSolutions() {
  const [activeId, setActiveId] = useState("feature-1");
  const portfolioSectionRef = useRef<any>(null);
  const [showCursor, setShowCursor] = useState(false);
  const [isExclusion, setIsExclusion] = useState(false);

  const cursorX = useMotionValue(500);
  const cursorY = useMotionValue(500);
  const springConfig = { damping: 25, stiffness: 300 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1
    };

    const callback = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          setActiveId(entry.target.id);
          console.log(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    const elements = document.querySelectorAll('.rel_links-anchor');

    elements.forEach(element => {
      observer.observe(element);
    });

    return () => {
      elements.forEach(element => {
        observer.unobserve(element);
      });
    };
  }, []);


  useEffect(() => {
    const mouseEnter = (e: any) => {
      setShowCursor(true);
    };
    const moveCursor = (e: any) => {

      if (
        e.target.classList.contains("cursor-hide") ||
        e.target.classList.contains("swiper-pagination-bullet") ||
        e.target.classList.contains("bullet-image")
      ) {
        setShowCursor(false);
      } else {
        setShowCursor(true);
        cursorX.set(e.clientX - 50);
        cursorY.set(e.clientY - 50);
      }
      if (e.target.classList.contains("cursor-exclusion")) {
        setIsExclusion(true);
      } else {
        setIsExclusion(false);
      }
    };
    const mouseLeave = () => {
      setShowCursor(false);
    };
    const checkDeviceType = () => {
      const isTouchDevice = !(
        "ontouchstart" in window || navigator.maxTouchPoints > 0
      );
      setShowCursor(isTouchDevice);
    };

    window.addEventListener("resize", checkDeviceType);
    portfolioSectionRef.current?.addEventListener("mousemove", moveCursor);
    portfolioSectionRef.current?.addEventListener("mouseenter", mouseEnter);
    portfolioSectionRef.current?.addEventListener("mouseleave", mouseLeave);
    return () => {
      window.removeEventListener("resize", checkDeviceType);
      portfolioSectionRef.current?.removeEventListener("mousemove", moveCursor);
      portfolioSectionRef.current?.removeEventListener(
        "mouseenter",
        mouseEnter
      );
      portfolioSectionRef.current?.removeEventListener(
        "mouseleave",
        mouseLeave
      );
    };
  }, []);

  return (
    <>

      <div className="w-full h-auto our-solutions-bg block max-xl:hidden " id="solutions" >
        <div className="page-y-width h-auto mx-auto section-y-space z-[21] relative">
          <div className="w-full min-h-[250vh] flex flex-col flex-nowrap">
            <div className="w-full h-auto sticky top-[10rem] ">
              <div className="w-full h-auto flex justify-center items-stretch gap-[2.2rem] ">
                <div className="w-[35%] ">
                  <div className="w-full h-auto p-[5.6rem] bg-pl-white">
                    <div className="w-full mb-[5.5rem] ">
                      <h5 className="text-[3.6rem] font-spacegro font-semibold text-pl-dark mb-[0.5rem]">Our Solutions</h5>
                      <p className="text-[2rem] font-spacegro font-normal text-pl-dark text-opacity-50 mb-[0.5rem]">Personalized mortgage and refinancing options, ensuring flexible terms tailored to your needs.</p>
                    </div>
                    <div className="w-full flex flex-col gap-[2rem]">
                      <div className={`w-full h-auto`}>
                        <div className={`w-full text-center py-[4rem] px-[1rem] ${activeId === "feature-1" ? "bg-gradient-to-r from-[#039cef31] to-[#ef28bd31] bg-opacity-10" : "bg-[#F9F9F9]"}`}>
                          <p className="text-[2.2rem] font-spacegro font-medium text-pl-dark ">Transformative ROI TM</p>
                        </div>
                        <div className={`w-full h-[0.3rem] ${activeId === "feature-1" && "services-gradient"}`}></div>
                      </div>
                      <div className={`w-full h-auto`}>
                        <div className={`w-full text-center py-[4rem] px-[1rem] ${activeId === "feature-2" ? "bg-gradient-to-r from-[#039cef31] to-[#ef28bd31] bg-opacity-10" : "bg-[#F9F9F9]"}`}>
                          <p className="text-[2.2rem] font-spacegro font-medium text-pl-dark ">IP Factory - What Why & What</p>
                        </div>
                        <div className={`w-full h-[0.3rem] ${activeId === "feature-2" && "services-gradient"}`}></div>
                      </div>
                      <div className={`w-full h-auto`}>
                        <div className={`w-full text-center py-[4rem] px-[1rem] ${activeId === "feature-3" ? "bg-gradient-to-r from-[#039cef31] to-[#ef28bd31] bg-opacity-10" : "bg-[#F9F9F9]"}`}>
                          <p className="text-[2.2rem] font-spacegro font-medium text-pl-dark ">Solution Accelerators</p>
                        </div>
                        <div className={`w-full h-[0.3rem] ${activeId === "feature-3" && "services-gradient"}`}></div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="w-[65%] relative" ref={portfolioSectionRef}>
                  <div className={`w-full h-auto absolute inset-0 z-[2] transition-opacity duration-500 ${activeId === "feature-1" ? "opacity-100" : "opacity-0"}`}>
                    <img src={require("../../../assets/images/solution-img-1.webp")} alt="image 1" />
                    <div className="w-full h-full p-[3.5rem] absolute top-0 left-0">
                      <div className="w-[65%] ml-auto h-full flex justify-end items-end">
                        <p className="text-[1.8rem] font-spacegro font-medium text-pl-white text-right">PrimeLend recognizes that a technology-first (AI First, Digital First, Cloud First) approach without a clear focus on ROI can lead to suboptimal outcomes for businesses.</p>
                      </div>
                    </div>
                  </div>
                  <div className={`w-full h-auto absolute inset-0 z-[3] transition-opacity duration-500 ${activeId === "feature-2" ? "opacity-100" : "opacity-0"}`}>
                    <img src={require("../../../assets/images/solution-img-2.webp")} alt="image 2" />
                    <div className="w-full h-full p-[3.5rem] absolute top-0 left-0">
                      <div className="w-[65%] ml-auto h-full flex justify-end items-end">
                        <p className="text-[1.8rem] font-spacegro font-medium text-pl-white text-right">PrimeLend recognizes that a technology-first (AI First, Digital First, Cloud First) approach without a clear focus on ROI can lead to suboptimal outcomes for businesses.</p>
                      </div>
                    </div>
                  </div>
                  <div className={`w-full h-auto absolute inset-0 z-[4] transition-opacity duration-500 ${activeId === "feature-3" ? "opacity-100" : "opacity-0"}`}>
                    <img src={require("../../../assets/images/solution-img-3.webp")} alt="image 3" />
                    <div className="w-full h-full p-[3.5rem] absolute top-0 left-0">
                      <div className="w-[65%] ml-auto h-full flex justify-end items-end">
                        <p className="text-[1.8rem] font-spacegro font-medium text-pl-white text-right">PrimeLend recognizes that a technology-first (AI First, Digital First, Cloud First) approach without a clear focus on ROI can lead to suboptimal outcomes for businesses.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="feature-1" className="rel_links-anchor top-[15%]"></div>
            <div id="feature-2" className="rel_links-anchor top-[45%]"></div>
            <div id="feature-3" className="rel_links-anchor top-[70%]"></div>
          </div>

        </div>
      </div>
      <div className="w-full h-auto our-solutions-bg hidden max-xl:block">
        <div className="page-y-width h-full mx-auto section-y-space z-[21] relative ">

          <div className="w-full h-auto">
            <div className="w-1/2 mx-auto text-center mb-[5rem]">
              <h2 className="text-[5.6rem] font-spacegro font-semibold text-pl-dark">Our Solutions</h2>
              <p className="text-[1.8rem] font-spacegro font-normal text-pl-darkgray">Personalized mortgage and refinancing options, ensuring flexible terms tailored to your needs.</p>
            </div>
            <div className="w-full h-auto">
              <Swiper

                slidesPerView={1}
                spaceBetween={16}
                loop
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 5000,
                  // pauseOnMouseEnter: true,
                  disableOnInteraction: false,
              }}
                speed={1000}

                modules={[Pagination, Autoplay]}
                className="w-full h-auto"
              >
                <SwiperSlide  >
                  <div className={`w-full h-auto  z-[4] relative`}>
                    <img className="h-auto" src={require("../../../assets/images/solution-img-1.webp")} alt="image 3" />
                    <div className="w-full h-full p-[3.5rem] absolute top-0 left-0">
                    <div className="w-[65%] ml-auto h-full flex justify-end items-end">
                    <div className="w-full">
                  <p className="text-[3rem] font-spacegro font-medium text-pl-white text-right mb-[2rem]">Transformative ROI TM</p>
                    <p className="text-[1.8rem] font-spacegro font-medium text-pl-white text-right">PrimeLend recognizes that a technology-first (AI First, Digital First, Cloud First) approach without a clear focus on ROI can lead to suboptimal outcomes for businesses.</p>
                    </div>
                    </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide  >
                  <div className={`w-full h-auto  z-[4] relative`}>
                    <img className="h-auto" src={require("../../../assets/images/solution-img-2.webp")} alt="image 3" />
                    <div className="w-full h-full p-[3.5rem] absolute top-0 left-0">
                      <div className="w-[65%] ml-auto h-full flex justify-end items-end">
                        <div className="w-full">
                      <p className="text-[3rem] font-spacegro font-medium text-pl-white text-right mb-[2rem]">IP Factory - What Why & What</p>
                        <p className="text-[1.8rem] font-spacegro font-medium text-pl-white text-right">PrimeLend recognizes that a technology-first (AI First, Digital First, Cloud First) approach without a clear focus on ROI can lead to suboptimal outcomes for businesses.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide  >
                  <div className={`w-full h-auto  z-[4] relative`}>
                    <img className="h-auto" src={require("../../../assets/images/solution-img-3.webp")} alt="image 3" />
                    <div className="w-full h-full p-[3.5rem] absolute top-0 left-0">
                    <div className="w-[65%] ml-auto h-full flex justify-end items-end">
                    <div className="w-full">
                    <p className="text-[3rem] font-spacegro font-medium text-pl-white text-right mb-[2rem]">Solution Accelerators</p>
                      <p className="text-[1.8rem] font-spacegro font-medium text-pl-white text-right">PrimeLend recognizes that a technology-first (AI First, Digital First, Cloud First) approach without a clear focus on ROI can lead to suboptimal outcomes for businesses.</p>
                      </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <motion.div
        className={`cursor ${showCursor ? "active" : ""} ${isExclusion ? "excactive" : null
          }`}
        style={{
          translateX: cursorXSpring,
          translateY: cursorYSpring,
        }}
      >
        <div className="w-full h-full flex justify-center items-center cursor-inner">
          <p className="readmore-cursor">Explore</p>
        </div>
      </motion.div>
    </>
  )
}
export default OurSolutions
