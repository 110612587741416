function BannerVideo(){
    return(
        <div className={`w-full h-full relative video-overlay`}>
      <video
        autoPlay
        muted
        loop
        className="w-full h-full object-cover object-center"
        // width="1920"
      >
        <source
          src={require("../../../videos/bannerVideo.mp4")}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
    )
}
export default BannerVideo