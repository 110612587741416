import gsap from "gsap";
import { useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ReadMoreArrow, ScrollMouseIcon } from "../../svg";

gsap.registerPlugin(ScrollTrigger);
function CaseStudies() {
    let caseStudiesRef = useRef<any>(null);

    const caseStudiesItems = [
        { title: "Retail Growth", imgSrc: "RetailGrowth.webp", bgColor: "blue-gradient" },
        { title: "Business Expansion", imgSrc: "BusinessExpansion.webp", bgColor: "orange-gradient" },
        { title: "Mortgage Refinancing", imgSrc: "MortgageRefinancing.webp", bgColor: "lightgray-gradient" },
        { title: "Homebuyer Success", imgSrc: "HomebuyerSuccess.webp", bgColor: "pink-gradient" },
        { title: "Startup Funding", imgSrc: "StartupFunding.webp", bgColor: "gold-gradient" },
        { title: "Investor Financing", imgSrc: "InvestorFinancing.webp", bgColor: "darkorange-gradient" },
        { title: "Debt Reduction", imgSrc: "DebtReduction.webp", bgColor: "teal-gradient" },
        { title: "Home Purchase", imgSrc: "HomePurchase.webp", bgColor: "brown-gradient" },
        { title: "Tech Expansion", imgSrc: "TechExpansion.webp", bgColor: "darkblue-gradient" },
        { title: "Mortgage Simplified", imgSrc: "MortgageSimplified.webp", bgColor: "darkgray-gradient" },
    ]

    useEffect(() => {
        const sections = gsap.utils.toArray(".case-studies-card");
        console.log(caseStudiesRef.current, ScrollTrigger);

        gsap.to(sections, {
            xPercent: -100 * (sections.length - 1),
            ease: "none",
            duration: 4,
            scrollTrigger: {
                trigger: caseStudiesRef.current,
                start: 'center center',
                pin: "#caseStudiesWrapper",
                scrub: true,
                markers: false,
                end: `+=${caseStudiesRef.current.offsetWidth}`,
                invalidateOnRefresh: true
            }
        });

        ScrollTrigger.refresh();

        console.log(caseStudiesRef, sections);
    }, []);

    return (
        <div className="w-full h-auto relative" id="casestudies">

            <div className="w-full bg-[#1B1D23] py-[12rem] case-studies-gradient block max-xl:hidden">
                <div className="w-full h-auto relative grid place-content-start pl-[11.5%] py-[5%] z-[21]" id="caseStudiesWrapper" >
                    <div className="w-full h-full flex shrink-0" ref={caseStudiesRef}>
                        <div className="w-[56rem] h-full z-[1] mx-[2.5rem] ml-0 case-studies-card ">
                            <div className="w-full h-full flex flex-col justify-between items-start">
                                <div className="w-full">
                                    <h4 className="text-[5.6rem] font-spacegro font-semibold text-pl-white mb-[1.5rem]">Case Studies</h4>
                                    <p className="text-[2.2rem] font-spacegro font-normal text-pl-white text-opacity-70">PrimeLend works closely with each client to tailor financial solutions, ensuring the best possible outcomes based on individual needs.</p>
                                </div>
                                <div className="w-full">
                                    <div className="w-auto flex justify-start items-center gap-[1.5rem]">
                                        <p className="text-[3.2rem] font-spacegro font-medium text-pl-white">Scroll</p>
                                        <div className="w-[2.5rem] h-auto">
                                            <ScrollMouseIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {caseStudiesItems.map((item: any, index: number) => (
                            <div key={index} className={`w-[56rem] h-auto z-[1] relative mx-[2.5rem] case-studies-card  ${item.bgColor}`}>
                                <div className="w-full h-auto p-[4rem] pt-0">
                                    <div className="w-full py-[3rem]">
                                        <p className="text-[3rem] font-spacegro font-semibold text-pl-white ">{item.title}</p>
                                    </div>
                                    <div className="w-full h-auto">
                                        <img src={require(`../../../assets/images/casestudies/${item.imgSrc}`)} alt={item.title} />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="w-[56rem] h-full z-[1] mx-[2.5rem] mr-0 case-studies-card dark-gradient">
                            <div className="w-full h-full flex justify-center items-center">
                               <div className="w-auto">
                                    <div className="w-[13.2rem] h-auto mx-auto">
                                        <ReadMoreArrow />
                                    </div>
                                    <p className="text-[5.6rem] font-spacegro font-semibold text-pl-white text-center">View all <br /> Case Studies</p>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="w-full h-auto hidden max-xl:block">
            <div className="w-1/2 mx-auto text-center mb-[5rem]">
              <h2 className="text-[5.6rem] font-spacegro font-semibold text-pl-dark">Case Studies</h2>
              <p className="text-[1.8rem] font-spacegro font-normal text-pl-darkgray">PrimeLend works closely with each client to tailor financial solutions, ensuring the best possible outcomes based on individual needs.</p>
            </div>
                <div className="page-y-width mx-auto grid grid-cols-2 gap-[2rem]">
                {caseStudiesItems.map((item: any, index: number) => (
                            <div key={index} className={`w-full h-auto z-[1] relative ${item.bgColor}`}>
                                <div className="w-full h-auto p-[4rem] pt-0">
                                    <div className="w-full py-[3rem]">
                                        <p className="text-[3rem] font-spacegro font-semibold text-pl-white ">{item.title}</p>
                                    </div>
                                    <div className="w-full h-auto">
                                        <img src={require(`../../../assets/images/casestudies/${item.imgSrc}`)} alt={item.title} />
                                    </div>
                                </div>
                            </div>
                        ))}
                         <div className="w-full h-full z-[1] dark-gradient py-[6rem]">
                            <div className="w-full h-full flex justify-center items-center">
                               <div className="w-auto">
                                    <div className="w-[13.2rem] h-auto mx-auto">
                                        <ReadMoreArrow />
                                    </div>
                                    <p className="text-[5rem] font-spacegro font-semibold text-pl-white text-center">View all <br /> Case Studies</p>
                               </div>
                            </div>
                        </div>
                </div>

            </div>
        </div>
    )
}
export default CaseStudies;


{/* <div className="w-full h-auto py-[8%] relative grid place-content-start" id="celebrityCodersWrapper" >
<div className="w-full h-full flex shrink-0" ref={caseStudiesRef}>
    <div className="w-[41rem] h-auto z-[1] mx-[6rem] coder-card "></div>
    <div className="w-[41rem] h-auto z-[1] mx-[6rem] coder-card "></div>
    {celeBrityCodersItems.map((coderItem: any, index: number) => (
        <div key={index} className="w-[41rem] h-[49.5rem] z-[1] mx-[6rem] coder-card">
            <div className="w-full h-auto rounded-[2rem] rounded-tl-[14.4rem] bg-[#F3F3FF] mb-8 px-[8%]">
                <div className="w-full h-auto grayscale group-hover:grayscale-0">
                    <img src={require(`../../../assets/images/${coderItem.imgSrc}`)} alt="Celebrity Coder" />
                </div>
            </div>
            
        </div>
    ))}
</div>
</div> */}