import {RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import Layout from './pages/layout';
import NotFound from './pages/notFound';
import Home from './pages/home';

function App() {
  const router = createBrowserRouter([
    {
      path: "",
      element: <Layout />,
      errorElement: <NotFound />,
      children: [
        {
          path: "",
          element: <Home />
        },
      ]
    }]);

  return (
    <RouterProvider router={router}></RouterProvider>
  );
}

export default App;
