import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

function FloatingMenu(){
    const menuLinks = [
        { label: "About Us", link: "/" },
        { label: "Unique Capabilities", link: "/" },
        { label: "Case Studies", link: "/" },
        { label: "Insights", link: "/" },
      ];
    

      const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > lastScrollY) {
                // Scrolling down, hide the menu
                setIsVisible(false);
            } else {
                // Scrolling up, show the menu
                setIsVisible(true);
            }
            setLastScrollY(window.scrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY]);

    return(
      <div
      className={`w-auto fixed bottom-[4.5rem] left-[50%] translate-x-[-50%] px-[5rem] py-[2.4rem] rounded-full z-50 floating-menu-bg transition-transform duration-300 ${
          isVisible ? "translate-y-0" : "translate-y-[150%]"
      }`}
  >
      <ul className="w-auto flex justify-center items-center gap-[4.4rem]">
          {menuLinks.map((menuLink, i) => (
              <li key={i} className="w-auto">
                  <a
                     className="text-[1.8rem] font-spacegro text-pl-white text-opacity-60 font-normal text-nowrap hover:text-opacity-100"
                      href={menuLink.link}
                  >
                      {menuLink.label}
                  </a>
                  {/* <NavLink
                      className={({ isActive }) =>
                          `text-[1.8rem] font-spacegro text-pl-white hover:text-opacity-100 ${
                              isActive
                                  ? "text-opacity-100 font-semibold"
                                  : "text-opacity-60 font-normal"
                          }`
                      }
                      to={menuLink.link}
                  >
                      {menuLink.label}
                  </NavLink> */}
              </li>
          ))}
      </ul>
  </div>
    )
}
export default FloatingMenu