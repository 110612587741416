function ContactUs(){
    return(
        <div className="w-full h-auto section-y-space bg-[#f8f8f8]">
            <div className="page-y-width h-auto mx-auto relative z-[21]">
                <div className="w-full flex flex-col justify-between items-start gap-[11rem]">
                    <div className="w-full">
                        <div className="w-[90%] max-xl:w-full">
                        <p className="text-[2.2rem] font-spacegro font-normal text-pl-dark text-opacity-50">Contact Us</p>
                        <p className="text-[7.8rem] max-xl:text-[7rem] font-spacegro font-medium text-pl-dark leading-tight">Let's make something <br className="hidden max-xl:block" /> great <br className="block max-xl:hidden" /> together</p>
                        </div>
                    </div>
                    <div className="w-full">
                        <p className="text-[2.2rem] font-spacegro font-normal text-pl-dark text-opacity-50">Get in touch</p>
                        <p className="text-[3.6rem] font-spacegro font-normal text-pl-dark"><a href="mailto:info@primelend.com">info@primelend.com</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactUs;