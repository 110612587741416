// import { ROIText } from "../../svg";
// import { WaveFour } from "../../svg/waveFour";
// import { WaveOne } from "../../svg/waveOne";
// import { WaveThree } from "../../svg/waveThree";
// import { WaveTwo } from "../../svg/waveTwo";

import BannerVideo from "../../UI/banner-video/banner-video";

function Banner(){
    return(
        <div className="w-full h-screen max-xl:h-auto overflow-hidden">
            <div className="w-full h-full bg-pl-primary  relative ">
                <div className="w-full h-full max-xl:h-auto">
                <BannerVideo />
                </div>

                {/* <div className="page-y-width absolute top-[15%] left-[50%] translate-x-[-50%]">
                    <img className="w-full h-full object-cover object-center" src={require("../../../assets/images/banner-without-bg.webp")} alt="Banner" />
                </div>

                <div className="w-full h-[80vh] absolute bg-transparent bottom-[-35%] left-0">
                    <WaveOne />
                </div>
                <div className="w-full h-[80vh] absolute bg-transparent bottom-[-35%] left-0">
                    <WaveTwo />
                </div>
                <div className="w-full h-[80vh] absolute bg-transparent bottom-[-35%] left-0">
                    <WaveThree />
                </div>
                <div className="w-full h-[80vh] absolute bg-transparent bottom-[-35%] left-0">
                    <WaveFour />
                </div> */}
                {/* <img className="w-full h-full object-cover object-center" src={require("../../../assets/images/banner.jpg")} alt="Banner" /> */}
            </div>
        </div>
    )
}
export default Banner;