import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { Navigation } from 'swiper/modules';
import { LeftArrowicon, RightArrowIcon } from "../../svg";

function FoundationTeam() {

    const foundationteamData = [
        {
            name: "Narayanasamy Natarajan",
            role: "Co-Founder & CEO",
            content: "PrimeLend is a trusted financial services provider specializing in mortgage lending, refinancing, and business loan solutions. Committed to personalized service, PrimeLend helps individuals and businesses secure the financing they need with flexible terms and expert guidance to achieve their financial goals.",
            imageWithBg: "team-1-bg.webp",
            imageWithoutBg: "team-1-nobg.webp"
        },
        {
            name: "Narayanasamy Natarajan",
            role: "Co-Founder & CEO",
            content: "PrimeLend is a trusted financial services provider specializing in mortgage lending, refinancing, and business loan solutions. Committed to personalized service, PrimeLend helps individuals and businesses secure the financing they need with flexible terms and expert guidance to achieve their financial goals.",
            imageWithBg: "team-2-bg.webp",
            imageWithoutBg: "team-2-nobg.webp"
        },
        {
            name: "Narayanasamy Natarajan",
            role: "Co-Founder & CEO",
            content: "PrimeLend is a trusted financial services provider specializing in mortgage lending, refinancing, and business loan solutions. Committed to personalized service, PrimeLend helps individuals and businesses secure the financing they need with flexible terms and expert guidance to achieve their financial goals.",
            imageWithBg: "team-1-bg.webp",
            imageWithoutBg: "team-1-nobg.webp"
        },
        {
            name: "Narayanasamy Natarajan",
            role: "Co-Founder & CEO",
            content: "PrimeLend is a trusted financial services provider specializing in mortgage lending, refinancing, and business loan solutions. Committed to personalized service, PrimeLend helps individuals and businesses secure the financing they need with flexible terms and expert guidance to achieve their financial goals.",
            imageWithBg: "team-2-bg.webp",
            imageWithoutBg: "team-2-nobg.webp"
        },
       
    ]
    return (
        <div className="w-full h-[90vh] max-xl:h-auto z-[21] relative">
            <div className="w-[88%] mx-auto section-y-space">
                <div className="w-full">
                    <h4 className="text-[3.6rem] font-spacegro font-semibold text-pl-dark mb-[2rem]">Meet Our Founding Team</h4>
                    <div className="w-full flex justify-end pr-[7%]">
                        <div className="flex justify-center items-center gap-4">
                            <button type="button" className="w-[6rem] h-[6rem] flex justify-center items-center team-left">
                                <div className="w-[2rem] h-auto">
                                    <LeftArrowicon />
                                </div>
                            </button>
                            <button type="button" className="w-[6rem] h-[6rem] bg-pl-dark flex justify-center items-center team-right">
                            <div className="w-[2rem] h-auto">
                                    <RightArrowIcon />
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-full mt-[3rem]">
                    <Swiper speed={1000} spaceBetween={10} slidesPerView={1.18} loop navigation={{ prevEl: ".team-left", nextEl: ".team-right", disabledClass: "opacity-30 pointer-events-none" }} modules={[Navigation]}
                    breakpoints={{
                        900: {
                          slidesPerView: 1.18,  
                        },
                        0: {
                          slidesPerView: 1,  
                        },
                      }} >
                        {foundationteamData.length > 0 &&
                            foundationteamData.map((option: any, index: any) => (
                                <SwiperSlide key={index}>
                                     {({ isActive }) => (
                                    <div className="w-[98%] h-auto max-xl:w-full">
                                        <div className="w-full h-auto flex justify-center items-stretch gap-[3rem]">
                                            <div className={`transition-all duration-500 ${isActive ? "w-[34.5rem] max-xl:w-[25rem] grayscale-0" : "w-[25rem] grayscale"}`}>
                                                <img className="w-full h-auto object-cover object-center" src={isActive ? require(`../../../assets/images/${option.imageWithoutBg}`) : require(`../../../assets/images/${option.imageWithBg}`)} alt="Founder Image" />
                                            </div>
                                            <div className="flex-1">
                                                <div className="w-[90%] max-xl:w-full h-full flex flex-col justify-between items-start ">
                                                    <p className="text-[3rem] font-spacegro font-normal text-pl-darkgray max-xl:text-[2.6rem]"><span className="text-[#E52715] font-mono ">"</span>PrimeLend is a trusted financial services provider specializing in mortgage lending, refinancing, and business loan solutions. Committed to personalized service, PrimeLend helps individuals and businesses secure the financing they need with flexible terms and expert guidance to achieve their financial goals. <span className="text-[#E52715] font-mono ">"</span></p>
                                                    <div className="">
                                                        <p className="text-[2.3rem] font-spacegro font-medium text-pl-dark">Narayanasamy Natarajan</p>
                                                        <p className="text-[1.6rem] font-spacegro font-light text-[#8F8F8F]">Co-Founder & CEO</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     )}
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
export default FoundationTeam