import { Outlet } from "react-router-dom"
import Footer from "../components/global-layouts/footer/footer"
import Header from "../components/global-layouts/header/header"
import FloatingMenu from "../components/global-layouts/floating-menu/floating-menu"
import VerticalLines from "../components/UI/vertical-lines/vertical-lines"

function Layout() {
    return (
        <div className="w-full h-auto relative custom-cursor">
            <Header />
            <FloatingMenu />
            <Outlet />
            <div className="w-full relative">
                <VerticalLines borderColor="border-pl-white border-opacity-20" />
                <div className="w-full">
                    <Footer />
                </div>
            </div>
        </div>
    )
}
export default Layout