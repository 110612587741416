import About from "../components/section/about/about"
import Banner from "../components/section/banner/banner"
import CaseStudies from "../components/section/case-studies/case-studies"
import ContactUs from "../components/section/contact-us/contact-us"
import FoundationTeam from "../components/section/foundation-team/foundation-team"
import Insights from "../components/section/insights/insights"
import OurSolutions from "../components/section/our-solutions/our-solutions"
import TestScroll from "../components/section/testscroll/textscroll"
import VerticalLines from "../components/UI/vertical-lines/vertical-lines"

function Home() {
    return (
        <div className="w-full h-auto ">
            <Banner />
            <div className="w-full relative">
                <VerticalLines borderColor="border-pl-bordergray" />
                <div className="w-full">
                    <About />
                </div>
            </div>
            <div className="w-full relative">
                <VerticalLines borderColor="border-[#6220DC] border-opacity-10" />
                <div className="w-full">
                    <OurSolutions />
                </div>
            </div>
           
            <div className="w-full relative">
                <VerticalLines borderColor="border-pl-bordergray" />
                <div className="w-full">
                    <FoundationTeam />
                </div>
            </div>
            <div className="w-full relative">
                <VerticalLines borderColor="border-white border-opacity-15" />
                <div className="w-full">
                    <CaseStudies />
                </div>
            </div>
            <div className="w-full relative">
                <VerticalLines borderColor="border-pl-bordergray" />
                <div className="w-full">
                    <Insights />
                    <ContactUs />
                </div>
            </div>
        </div>
    )
}
export default Home