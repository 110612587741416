import { useEffect, useRef } from "react";
import { gsap } from "gsap";

const TextButton = ({ text }:any) => {
    const buttonRef = useRef<any>(null);
    const spanRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({ paused: true });

    tl.to(spanRef.current, { duration: 0.2, yPercent: -150, ease: "power2.in" });
    tl.set(spanRef.current, { yPercent: 150 });
    tl.to(spanRef.current, { duration: 0.2, yPercent: 0 });

    const button = buttonRef.current;
    button.addEventListener("mouseenter", () => tl.play(0));

    return () => {
      button.removeEventListener("mouseenter", () => tl.play(0));
    };
  }, []);

  return (
    <button
      className="inline-grid border-none bg-pl-dark px-[3.5rem] py-[0.8rem] text-[1.8rem] font-spacegro font-semibold text-center text-pl-white overflow-hidden cursor-pointer"
      ref={buttonRef}
    >
      <span ref={spanRef}>{text}</span>
    </button>
  );
  };
  export default TextButton;