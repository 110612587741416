import { useEffect, useState } from "react";
import { PrimeLendLogo } from "../../svg"

function Header(){
    const [showLogo, setShowLogo] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setShowLogo(false);
            } else {
                setShowLogo(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <div className="w-full h-auto fixed top-0 left-0 z-50">
            <div className="page-y-width h-auto mx-auto pt-[5.5rem] pb-[1rem]">
                <div className="w-full flex justify-start items-center">
                <div className={`w-[21.5rem] h-auto transition-opacity duration-300 ${showLogo ? 'opacity-100' : 'opacity-0'}`}>
                        <PrimeLendLogo />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header