import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper/modules';
import { useRef, useState } from "react";

function Insights() {

    const insightsItems = [
        {
            items: [
                {
                    title: "Credit Score Improvement",
                    imageSrc: "CreditScoreImprovement.webp"
                },
                {
                    title: "Risk Management",
                    imageSrc: "RiskManagement.webp"
                }
            ]
        },
        {
            items: [
                {
                    title: "Market Trend Analysis",
                    imageSrc: "MarketTrendAnalysis.webp"
                },
                {
                    title: "Future Financing",
                    imageSrc: "FutureFinancing.webp"
                }
            ]
        },
        {
            items: [
                {
                    title: "Industry Updates",
                    imageSrc: "IndustryUpdates.webp"
                },
                {
                    title: "Homebuyer Education",
                    imageSrc: "HomebuyerEducation.webp"
                }
            ]
        },
        {
            items: [
                {
                    title: "Client Success",
                    imageSrc: "ClientSuccess.webp"
                },
                {
                    title: "Investment Growth",
                    imageSrc: "investmentGrowth.webp"
                }
            ]
        },
        {
            items: [
                {
                    title: "Expert Lending",
                    imageSrc: "ExpertLending.webp"
                },
                {
                    title: "Understanding Loan",
                    imageSrc: "UnderstandingLoan.webp"
                }
            ]
        },
        {
            items: [
                {
                    title: "Economic Challenge",
                    imageSrc: "EconomicChallenge.webp"
                },
                {
                    title: "Practical Financial",
                    imageSrc: "PracticalFinancial.webp"
                }
            ]
        },
        {
            items: [
                {
                    title: "Credit Score Improvement",
                    imageSrc: "CreditScoreImprovement.webp"
                },
                {
                    title: "Risk Management",
                    imageSrc: "RiskManagement.webp"
                }
            ]
        },
        {
            items: [
                {
                    title: "Market Trend Analysis",
                    imageSrc: "MarketTrendAnalysis.webp"
                },
                {
                    title: "Future Financing",
                    imageSrc: "FutureFinancing.webp"
                }
            ]
        },
        {
            items: [
                {
                    title: "Industry Updates",
                    imageSrc: "IndustryUpdates.webp"
                },
                {
                    title: "Homebuyer Education",
                    imageSrc: "HomebuyerEducation.webp"
                }
            ]
        },
        {
            items: [
                {
                    title: "Client Success",
                    imageSrc: "ClientSuccess.webp"
                },
                {
                    title: "Investment Growth",
                    imageSrc: "investmentGrowth.webp"
                }
            ]
        },
        {
            items: [
                {
                    title: "Expert Lending",
                    imageSrc: "ExpertLending.webp"
                },
                {
                    title: "Understanding Loan",
                    imageSrc: "UnderstandingLoan.webp"
                }
            ]
        },
        {
            items: [
                {
                    title: "Economic Challenge",
                    imageSrc: "EconomicChallenge.webp"
                },
                {
                    title: "Practical Financial",
                    imageSrc: "PracticalFinancial.webp"
                }
            ]
        },
    ];
    const swiperRef = useRef<any>(null);
    const [hoveredIndex, setHoveredIndex] = useState<any>({
        parent: null,
        child: null
    });
    const stopAutoplay = () => {
        console.log("stop");
        
        if (swiperRef.current && swiperRef.current.autoplay) {
            swiperRef.current.autoplay.stop();
        }
    };

    // Custom function to start autoplay
    const startAutoplay = () => {
        console.log("start", swiperRef.current);
        if (swiperRef.current && swiperRef.current.autoplay) {
            swiperRef.current.autoplay.start();
        }
    };

    return (
        <div className="w-full h-auto bg-[#f8f8f8] " id="insights">
            <div className="w-full h-auto section-y-space relative z-[21]">
                <div className="w-full mb-[7rem]">
                    <div className="w-1/2 mx-auto text-center">
                        <h2 className="text-[5.6rem] font-spacegro font-semibold text-pl-dark">Insights</h2>
                        <p className="text-[1.8rem] font-spacegro font-normal text-pl-darkgray">Insights from PrimeLend’s financial experts on navigating <br /> complex lending landscapes.</p>
                    </div>

                </div>
                <div className="w-full h-auto">
                    <Swiper
                    onSwiper={(swiper:any) => (swiperRef.current = swiper)}
                        slidesPerView={"auto"}
                        spaceBetween={16}
                        loop
                        freeMode={true}
                        pagination={{
                            clickable: true,
                        }}
                        speed={10000}
                        autoplay={{
                            delay: 0,
                            // pauseOnMouseEnter: true,
                            disableOnInteraction: false,
                        }}
                        modules={[FreeMode, Pagination, Autoplay]}
                        className="mySwiper"
                        
                    >
                        {insightsItems.length > 0 &&
                            insightsItems.map((option: any, index: any) => (
                                <SwiperSlide key={index} >
                                    <div className={`w-full h-auto ${index % 2 !== 0 ? 'mt-20' : ''}`} onMouseEnter={stopAutoplay} 
                                        onMouseLeave={startAutoplay}>
                                        {option.items.map((item:any, j:number) => (
                                            <div key={j} className={`w-[28.5rem] h-auto mb-[1.6rem] ${hoveredIndex.parent !== null ? hoveredIndex.parent === index && hoveredIndex.child === j ? 'opacity-100' : 'opacity-75' : "opacity-100"}`}  
                                            onMouseEnter={() => setHoveredIndex({
                                                parent: index,
                                                child: j
                                            })} 
                                            onMouseLeave={() => setHoveredIndex({
                                                parent: null,
                                                child: null
                                            })}>
                                                <div className="w-full h-auto bg-pl-white">
                                                    <div className="py-[2rem] px-[2.5rem]">
                                                        <p className="text-[1.6rem] font-spacegro font-medium text-pl-dark">{item.title}</p>
                                                    </div>
                                                    <div className="w-full h-auto">
                                                        <img src={require(`../../../assets/images/insights/${item.imageSrc}`)} alt="Insights"/>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </div>

        </div>
    )
}
export default Insights



