interface VerticalLinesProps {
    borderColor : string;
}
function VerticalLines({borderColor}:VerticalLinesProps){
    return(
        <div className="w-full h-full absolute top-0 left-0 z-20">
                    <div className="page-y-width mx-auto h-full">
                        <div className="grid grid-cols-3 h-full">
                            <div className={`w-full h-full border-l ${borderColor}`}></div>
                            <div className={`w-full h-full border-l ${borderColor}`}></div>
                            <div className={`w-full h-full border-x ${borderColor}`}></div>
                        </div>
                    </div>
                </div>
    )
}
export default VerticalLines