import { NavLink } from "react-router-dom";
import { PrimeLendLogo } from "../../svg";

function Footer() {
    const menuLinks = [
        { label: "About Us", link: "/" },
        { label: "Unique Capabilities", link: "/" },
        { label: "Case Studies", link: "/" },
        { label: "Insights", link: "/" },
      ];
    return (
        <div className="w-full h-auto">
        <div className="w-full bg-[#1B1D23]">
            <div className="page-y-width h-auto mx-auto pt-[7rem]">
                <div className="w-full h-auto flex justify-between items-start mb-[11rem]">
                    <div className="w-1/2">
                        <div className="w-full">
                            <div className="w-[28rem] h-auto mb-4">
                                <PrimeLendLogo />
                            </div>
                            <p className="text-[2rem] font-spacegro font-normal text-pl-white text-opacity-70">We're all about making performance marketing channels work <br /> for your business, for the long term.</p>
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="w-full flex justify-end items-center gap-[2.6rem]">
                                <div className="w-[6rem] h-[6rem] bg-pl-white bg-opacity-10 rounded-full flex justify-center items-center">
                                    <div className="w-auto h-[2.5rem]">
                                        <img src={require("../../../assets/images/insta.webp")} alt="Instagram" />
                                    </div>
                                </div>
                                <div className="w-[6rem] h-[6rem] bg-pl-white bg-opacity-10 rounded-full flex justify-center items-center">
                                    <div className="w-auto h-[2.5rem]">
                                        <img src={require("../../../assets/images/fb.webp")} alt="Facebook" />
                                    </div>
                                </div>
                                <div className="w-[6rem] h-[6rem] bg-pl-white bg-opacity-10 rounded-full flex justify-center items-center">
                                    <div className="w-auto h-[2.5rem]">
                                        <img src={require("../../../assets/images/LinkedIn.webp")} alt="linkedin" />
                                    </div>
                                </div>
                                <div className="w-[6rem] h-[6rem] bg-pl-white bg-opacity-10 rounded-full flex justify-center items-center">
                                    <div className="w-auto h-[2.5rem]">
                                        <img src={require("../../../assets/images/twitter.webp")} alt="Twitter" />
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="w-full border-t border-pl-white border-opacity-10 pt-[3.2rem] pb-[5.2rem]">
                <ul className="w-auto flex justify-start items-center gap-[9.5rem]">
                {menuLinks.map((menuLink, i) => (
                        <li key={i} className="w-auto">
                            <NavLink
                            className={({ isActive }) => `text-[2.2rem] font-spacegro text-pl-white hover:text-opacity-100 ${isActive ? "text-opacity-100 font-semibold" : "text-opacity-60 font-normal" } `}
                            to={menuLink.link}>
                            {menuLink.label}
                            </NavLink>
                        </li>
                        ))}
            </ul>
                </div>
            </div>
        </div>
        <div className="w-full h-auto bg-pl-white p-[3rem]">
                <p className="text-[1.7rem] font-spacegro font-normal text-[#1B1D23] text-center">Copyrights © Primelend Inc 2024. All Rights Reserved.</p>
        </div>


        </div>
    );
}
export default Footer;
