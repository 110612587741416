import { PlayVideoIcon } from "../../svg";
import TextButton from "../../UI/text-button/text-button";

function About(){
    return(
        <div className="w-full h-auto section-y-space z-[21] relative" id="about">
            <div className="page-y-width h-auto mx-auto ">
                <div className="w-full relative">
                    <div className="w-full absolute top-[3.8rem] left-[6.5rem]">
                        <p className="text-[2rem] font-spacegro font-medium text-pl-white">About PrimeLend</p>
                    </div>
                    <div className="w-full h-[52rem] max-lg:h-[30rem] about-us-banner">
                    </div>
                    <div className="w-full absolute bottom-[3rem] right-[3rem]">
                        <div className="w-[12.5rem] h-auto ml-auto cursor-pointer">
                            <PlayVideoIcon />
                        </div>
                    </div>
                </div>
                <div className="w-full h-auto mt-[3.5rem]">
                    <div className="w-full flex justify-center items-start">
                        <div className="w-1/2 h-auto">
                        <div className="w-[90%] h-auto">
                            <h3 className="text-[3.6rem] font-spacegro font-semibold text-pl-dark">Accelerating Modernization with Advanced Technologies</h3>
                        </div>
                        </div>
                        <div className="w-1/2 h-auto">
                        <div className="w-full h-auto">
                            <p className="text-[1.8rem] font-spacegro font-normal text-pl-darkgray mb-[1.9rem]">We're all about making performance marketing channels work for your business, for the long term.</p>
                            <p className="text-[1.8rem] font-spacegro font-normal text-pl-darkgray mb-[4rem]">Data, experience and expert thought fuels our full-funnel strategies. Our digital experts are skilled in achieving continued and sustainable growth and generating results that really impact your business. Our winning formula of digital excellence and solid client relationships has really cemented us as a leading performance marketing agency that you can trust.</p>
                            {/* <button type="button" className="border-none bg-pl-dark px-[3.5rem] py-[0.8rem] text-[1.8rem] font-spacegro font-semibold text-center text-pl-white">Learn More</button> */}
                            <TextButton text="Learn More"/>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About;